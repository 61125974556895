import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: flex;
    ${MEDIA.TABLET`
      flex-direction: column-reverse;
    };
  `}
    list-style: none;
    padding: 0;
    li {
      text-transform: uppercase;
      font-size: 1.7rem;
      ${MEDIA.TABLET`
      text-align: right;
      padding-bottom: 1rem;
      `}
      & + li {
        margin-left: 3rem;
      }
    }
  }
  .email-text {
    ${MEDIA.TABLET`
        display: none;
      `};
  }
  .email-icon {
    ${MEDIA.MIN_TABLET`
        display: none;
      `};
  }
`;

import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  a {
    color: #fff;
    ${({ darkMode }) =>
      darkMode &&
      `
    color: #000;
  `}
    transition: color 0.2s ease;
    text-decoration: none;
  }
`;

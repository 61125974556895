import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';

const Layout = ({ bgImage, data, children, darkMode, noBg }) => (
  <HeroDiv bgImage={bgImage} noBg={noBg}>
    <GlobalStyle />
    <Head />
    <Header title={data.site.siteMetadata.siteTitle} darkMode={darkMode} />
    {children}
  </HeroDiv>
);

Layout.propTypes = {
  bgImage: PropTypes.string,
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  darkMode: PropTypes.bool,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

const HeroDiv = styled.div`
  ${({ bgImage, noBg }) => `
  background: ${!noBg &&
    `radial-gradient(rgba(169,169,169,0.5), transparent), url(${bgImage}) no-repeat center center fixed;`}
  background-size: cover;
  height: 100vh;
  position: relative;
  transition: transform 0.1s cubic-bezier(0.12, 0, 0.39, 0);
  `}
`;

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;

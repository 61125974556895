import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ darkMode, title }) => (
  <AnimatedContainer
    style={{
      position: 'absolute',
      height: '100px',
      width: '100%',
      textShadow: !darkMode && 'rgba(100,100,100,0.8) 0px 0px 16px',
    }}
  >
    <Container darkMode={darkMode}>
      <Link to="/">
        <Title as="h1" size="large">
          {title}
        </Title>
      </Link>

      <Nav darkMode={darkMode} />
    </Container>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  darkMode: PropTypes.bool,
};

export default Header;

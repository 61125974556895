/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';

const Nav = ({ darkMode }) => (
  <Container>
    <ul>
      <li>
        <a href="mailto:hello@luismulet.photo" target="_emailme">
          <span className="email-text">hello@luismulet.photo</span>
          <img
            src="mail.png"
            alt="hello@luismulet.photo"
            height="18px"
            className="email-icon"
            style={{ filter: darkMode && 'invert(1)' }}
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/luismulet/"
          target="_go_insta"
          className="email-link"
        >
          <img
            src="insta.png"
            alt="instagram.com/luismulet"
            height="18px"
            style={{ filter: darkMode && 'invert(1)' }}
          />
        </a>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
    </ul>
  </Container>
);

export default Nav;
